import config, { AppLanguage } from '@config';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from './store';

interface LayoutState
{
    /** The language of the app */
    language: AppLanguage;
    /** Whether the app is starting up */
    isAppStartingUp: boolean;
    /** Whether the app is loading async data (from the API or elsewhere) */
    isAppLoadingData: boolean;
}

const initialState: LayoutState = {
    language: config.defaultLanguage,
    isAppStartingUp: true,
    isAppLoadingData: false
};

export const layoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        setAppLanguage: (state, action: PayloadAction<AppLanguage>) => {
            state.language = action.payload;
        },
        setIsAppStartingUp: (state, action: PayloadAction<boolean>) => {
            state.isAppStartingUp = action.payload;
        },
        setIsAppLoadingData: (state, action: PayloadAction<boolean>) => {
            state.isAppLoadingData = action.payload;
        }
    }
});

export const { 
    setAppLanguage,
    setIsAppStartingUp,
    setIsAppLoadingData
} = layoutSlice.actions;

export default layoutSlice.reducer;