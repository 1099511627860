import { FunctionComponent, useEffect, useState } from 'react';
import { useLoad, useTranslate } from '@xFrame4/components/Hooks';
import Layout from '@components/layout/Layout';
import Page, { PageProps } from './Page';

interface HomePageProps extends PageProps
{

}

const HomePage: FunctionComponent<HomePageProps> = (props) =>
{
    const t = useTranslate();

    /** Render */
    return (
        <>
            <Layout>
                <Page 
                    className="home-page"
                >
                    {t('HOME_PAGE')}
                </Page>
            </Layout>
        </>
    );
}

export default HomePage;