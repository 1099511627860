import { FunctionComponent, ReactNode } from 'react';

export interface PageProps
{
    children?: ReactNode;
    className?: string;
}

const Page: FunctionComponent<PageProps> = (props) =>
{
    /** Render */
    return (
        <div className={`page ${props.className ?? ''}`}>
            <div className="container">
                {props.children}
            </div>
        </div>
    );
}

export default Page;